import { FormEvent, useEffect, useState } from "react"
import BaseInput from "../../../../components/baseInput";
import { BaseButton } from "../../../../components/buttons";
import BaseSelectInput from "../../../../components/baseSelectInput";
import { GetRequest, PostRequest } from "../../../../includes/functions";
import { RoleProps } from "../../sections/rolesAndPermissions";
interface FormDataProp {
  email?:string;
  role?: string;
}

interface InviteAdminComponentprops {
  onClose: () => void;
}
export const InviteAdminComponent = (props: InviteAdminComponentprops) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [listOfRoles, setListOfRoles] = useState<RoleProps[]>([]);
  const [formData,setFormData] = useState<FormDataProp>({
    email:"",
    role:""
  })
  const GetRoles = ()=>{
    GetRequest("role",{}).then((res)=>{
      if(res.status)
      {
        const RoleList: RoleProps[] = res.data.roles;
        setListOfRoles(RoleList);
      }
    })
  }
  const handleSubmit =(e:FormEvent)=>{
    e.preventDefault();
    setLoading(true);
    const data = {
      email:formData.email,
      role:formData.role
    }
    PostRequest(`user`,data,true).then((res)=>{
      setLoading(false);
      if(res.status)
    {
      props.onClose()
    }
    })
    }
    useEffect(()=>{
      GetRoles();
    },[])
  return <div className="modal" tabIndex={-1} >
    <div className="modal-dialog" >
      <div className="modal-content" style={{ borderRadius: 20 }}>
        <div className="modal-header">
          <h5 className="modal-title">Invite admin</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.onClose}></button>
        </div>
        <div className="modal-body p-3">
          <form
          onSubmit={handleSubmit}
          >
              <div className="pt-3" >
                <BaseInput
                  label="Email"
                  name='email'
                  type='text'
                  max={80}
                  placeholder="e.g john@mail.com"
                  onValueChange={({target})=>{
                    setFormData({...formData,email:target.value});
                  }}
                  value={formData.email}
                  required
                  />
                   <BaseSelectInput
                  label="Role"
                  name='role'
                  options={listOfRoles.map((a,i)=>{
                    return {
                      title:a.name!,
                      value:a._id!
                    }
                  })}
                  placeholder="e.g Hr manager"
                  onValueChange={(d)=>{
                    setFormData({...formData,role:d.value});
                  }}
                  value={formData.role!}
                  required
                  />
                {/* <BaseSelectInput 
                label="Role"
                required
                placeholder="Select role"
                value={formData.role}
                name="role"
                options={[
                  {name:"Super admin",value:"Super-admin"},
                  {name:"Admin",value:"Admin"},
                  {name:"Business manager",value:"Business manager"}
                ]}
                onValueChange={(d)=>{
                  setFormData({...formData,role:d?.value});
                }}
                /> */}
              </div>
              <div className="row px-3 mt-5">
                <BaseButton
                  loading={loading}
                  style={{ minWidth: 130 }}
                 type="submit"
                >Send invitation
                </BaseButton>
              </div>
          </form>
        </div>
      </div>
    </div>
  </div>
}