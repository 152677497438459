/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import EditIcon from "../../../../assets/icons/editIcon";
import TrashIcon from "../../../../assets/icons/trashIcon";
import { ConfirmDialogComponent } from "../../components/confirmDialog";
import { EditUserComponent } from "../../components/editUser";
import { Pagination } from "../../../../components/pagination";
import { GetRequest, PostRequest } from "../../../../includes/functions";
import { BusinessProps } from "../../../../includes/types";
import moment from "moment";
import { BaseLoader } from "../../../../components/baseloader";
import { NoBusinessIcon } from "../../../../assets/icons/NoBusinessIcon";
import { WhiteButton } from "../../../../components/buttons";
import { EyeOpen } from "../../../../assets/icons/eye";
import { Menu } from "./invitations";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { emitEvent } from "../../../../includes/customEventHook";
import { CONSTANTS } from "../../../../includes/constant";
import DotIcon from "../../../../assets/icons/dot";
import ThreeVerticalDotsIcon from "../../../../assets/icons/threeDots";
import { ViewBusinessDetailsComponent } from "../business";

interface ActiveTableProps {
  searchText: string;
  onAddNewBusiness?: () => void;
}

const ActiveTable = (props: ActiveTableProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [updating, setUpdating] = useState<boolean>(false);
  const [showConfirmDailog, setShowConfirmDailog] = useState<boolean>(false);
  const [showEditUser, setShowEditUser] = useState<boolean>(false);
  const [listOfBusiness, setListOfBusiness] = useState<BusinessProps[]>([])
  const [startDate, setStartDate] = useState<string>(moment().subtract(3, "M").toISOString());
  const [endDate, setEndDate] = useState<string>(moment().toISOString());
  const [pageSize, setPageSize] = useState<number>(50);
  const [fetching, setFetching] = useState(false);
  const [filterString, setFilterString] = useState<string>('');
  const navigate = useNavigate();

  const GetAllBusinesses = (page: number) => {
    setFetching(true);
    GetRequest(`businesses?itemsPerPage=${pageSize}&pageNumber=${page}&startDate=${startDate}&endDate=${endDate}`, 
      {}, false).then((res) => {
      console.log('API to get active business', res);
      setFetching(false);
      if (res.status) {
        const BusinessList: BusinessProps[] = res.data.businesses as BusinessProps[]
        setListOfBusiness(BusinessList.filter((a, i) => a.status === "Active"));
        const data: any = BusinessList as any;
        emitEvent(CONSTANTS.Events.reloadAllBusiness, { detail: data })
      }
    }) 
    .catch((error) => {
      setFetching(false);
      console.error('Error fetching businesses:', error);
    });
  }

  const [selectedBusiness, setSelectedBusiness] = useState<BusinessProps | null>(null);
  const [loading, setLoading] = useState(false);

  const DeleteUser = (id: any) => {
    setLoading(true)
    PostRequest("delete:business", { businessId: id }, true).then((response) => {
      setLoading(false);
      if(response.status)
      {
      setListOfBusiness(listOfBusiness.filter((a, i) => a._id !== id))
      setShowConfirmDailog(false)
      }
    })
  }

  const UpdateUser = (values: any) => {
    setUpdating(true)
    PostRequest("patch:admin/user", values, true).then((response) => {
      setUpdating(false);
      if (response.status) {
        GetAllBusinesses(currentPage);
        setShowEditUser(false)
      }
    })
  }
  useEffect(() => {
    GetAllBusinesses(currentPage);
    window.addEventListener("reloadActiveTable", (event: any) => {
      if (event.detail) {
        setEndDate(event.detail.endDate);
        setStartDate(event.detail.startDate);
        setTimeout(() => {
          GetAllBusinesses(1);
        }, 1000)
      }
    });
  }, [])

  const Alldata = listOfBusiness.filter((a, i) => 
    String(a.name).toLowerCase().includes(String(props.searchText).toLowerCase()) || String(a.email).toLowerCase().includes(String(props.searchText).toLowerCase()) || String(a.firstName).toLowerCase().includes(String(props.searchText).toLowerCase())  || String(a.lastName).toLowerCase().includes(String(props.searchText).toLowerCase()))

  return <>
    <table className="table">
      <thead>
        <tr>
          <th scope="col">S.No.</th>
          <th scope="col">Company Name</th>
          <th scope="col">Email Address</th>
          {/* <th scope="col">Admin name</th> */}
          <th scope="col">Staff strength</th>
          <th scope="col">Status</th>
          <th scope="col">Date Joined</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        {fetching && <tr >
          <td colSpan={10}>
            <BaseLoader /> <small className='fs-small'>Fetching...</small>
          </td>
        </tr>}
        {Alldata.length === 0 ? <tr >
          <td colSpan={7}>
            <div className="text-center p-5">
              <NoBusinessIcon />
              <div className="fs-6 fw-bold my-3">No business added yet</div>
              <WhiteButton
                onClick={() => {
                  if(props.onAddNewBusiness)
                  {
                  props.onAddNewBusiness()
                  }
                }}
              >
                <span className="mx-2">Add new business</span>
              </WhiteButton>
            </div>
          </td>
        </tr> : Alldata.map((a, i) => 
        <tr key={i}>
          <td>{i + 1}</td>
          <td>{a.name}</td>
          <td>{a.email}</td>
          {/* <td>{a.admin.name}</td> */}
          <td>{a.employees?.length}</td>
          <td><div className={String(a.status).toLowerCase().includes("cancelled")?"status-suspended":"status-active"}>{String(a.status).toLowerCase().includes("cancelled")?"Cancelled":a.status}</div></td>
          <td>{moment(a.createdAt).format("Do, MMM YYYY")}</td>
          <td >

          <Menu 
           onValue={(d)=>{

            if(d.action === "view")
            {
            navigate("/dashboard/" + CONSTANTS.Routes.Businesses + "?id=" + a._id)
            }
            if(d.action === "edit")
              {
              setSelectedBusiness({...a,action:"edit"});
              setShowEditUser(true);
              }
              if(d.action === "delete")
                {
                setSelectedBusiness({...a,action:"delete"});
                setShowConfirmDailog(true);
                }
          }}
           list={[
            {title:"View",value:"view"},
            {title:"Edit",value:"edit"},
            {title:"Delete",value:"delete"}
           ]}
          type="employee"
           >
            <ThreeVerticalDotsIcon  />
            </Menu>
          </td>
        </tr>)}
      </tbody>
    </table>
    <Pagination
      onFilterRow={(d) => {
        setPageSize(d)
      }}
      onPage={(d) => {
        GetAllBusinesses(d)
      }}
    />

    {showConfirmDailog && <ConfirmDialogComponent
      onClose={() => {
        setShowConfirmDailog(false)
      }}
      data={selectedBusiness!}
      loading={loading}
      title="Delete business?"
      confirm={() => {
        if(selectedBusiness?.action === "delete")
        {
        DeleteUser(selectedBusiness?._id);
        }
      }}
    />}
    {showEditUser && <ViewBusinessDetailsComponent
    onClose={()=>{
      setShowEditUser(false);
    }}
    data={selectedBusiness}
    />}
  </>
}
export default ActiveTable;
